import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import useAppUtils from "../../utils/useAppUtils";
import axios from "axios";
import TreeChart from "./OrgChartTree";
import { Pagination } from "../Global/Table2";
import Calendar from 'react-calendar';
import { API_BASE_URL_WARE } from "../../utils/https";

const Hierarchy = () => {
  const { navigate } = useAppUtils();
  const [data, setData] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    filterType: "",
    searchQuery: "",
    startDate: null,
    endDate: null,
    selectedGroup: "", // New state for group filtering
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL_WARE}/productlist`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.status) {
          const { data: productData, unitlist } = response.data;
  
          // Simply assign group names according to the index value from unitlist
          const updatedData = productData.map((product, index) => ({
            ...product,
            group_names: unitlist[index]?.group_name || "N/A", // Fetch group names based on index
          }));
  
          setData(updatedData);
        }
      } catch (error) {
        console.error("Error fetching warehouse data:", error);
      }
    };
    fetchData();
  }, [token]);

  const filteredData = useMemo(() => {    
    let filtered = data;

    if (filterCriteria.filterType) {
      filtered = filtered.filter((item) => {
        const normalizedLocation = item.location.toLowerCase();
        if (filterCriteria.filterType === "Inside-Warehouse") return normalizedLocation.includes("inside");
        if (filterCriteria.filterType === "Outside-Warehouse") return normalizedLocation.includes("outside");
        return true;
      });
    }

    if (filterCriteria.searchQuery) {
      filtered = filtered.filter(
        (item) => item.name && item.name.toLowerCase().includes(filterCriteria.searchQuery.toLowerCase())
      );
    }

    if (filterCriteria.startDate && filterCriteria.endDate) {
      filtered = filtered.filter((item) => {
        const itemDate = new Date(item.create_date);
        return itemDate >= new Date(filterCriteria.startDate) && itemDate <= new Date(filterCriteria.endDate);
      });
    }

    if (filterCriteria.selectedGroup) {
      filtered = filtered.filter(
        (item) => item.group_names && item.group_names.includes(filterCriteria.selectedGroup)
      );
    }
    

    return filtered;
  }, [data, filterCriteria]);

  const handleFilterChange = (filterType) => {
    setFilterCriteria((prev) => ({ ...prev, filterType, selectedGroup: "" })); // Reset group filter
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setFilterCriteria((prev) => ({ ...prev, searchQuery: e.target.value }));
    setCurrentPage(1);
  };

  const handleDateChange = ([start, end]) => {
    setFilterCriteria((prev) => ({ ...prev, startDate: start, endDate: end }));
    setCurrentPage(1);   
  };

  const handleGroupClick = (groupName) => {
    setFilterCriteria((prev) => ({ ...prev, selectedGroup: groupName }));
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Container>
      <Title className="hide">Warehouse Simulation</Title>
      <CalContainer>
        <CalenderContainer>
          <Calendar selectRange onChange={handleDateChange} value={[filterCriteria.startDate, filterCriteria.endDate]} />
        </CalenderContainer>
        <VisualizationContainer>
          <TreeChart onGroupClick={handleGroupClick} />
        </VisualizationContainer>
      </CalContainer>
      <Button onClick={() => navigate("/manage_role")}>Create Order</Button>
      <hr />
      <Button>Equipment signature</Button>
      <Button onClick={() => navigate("/warehouse_manage/product")}>Product reconciliation</Button>
      <Button onClick={() => navigate("/warehouse_manage/add_product")}>Adding new equipment</Button>

      <SearchBar
        type="text"
        placeholder="Search by product name..."
        value={filterCriteria.searchQuery}
        onChange={handleSearchChange}
      />

      <Button onClick={() => handleFilterChange("Inside-Warehouse")} active={filterCriteria.filterType === "Inside-Warehouse"}>
        Inside-Warehouse
      </Button>
      <Button onClick={() => handleFilterChange("Outside-Warehouse")} active={filterCriteria.filterType === "Outside-Warehouse"}>
        Outside-Warehouse
      </Button>
      <Button onClick={() => handleFilterChange("")} active={filterCriteria.filterType === ""}>
        Show All
      </Button>

      <TableContainer>
        <Table>
          <thead>
            <tr>
              <Th>ID</Th>
              <Th>Product Name</Th>
              <Th>Category Name</Th>
              <Th>Location</Th>
              <Th>Create Date</Th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length === 0 ? (
              <tr>
                <Td colSpan="5">No data available</Td>
              </tr>
            ) : (
              currentItems.map((item) => (
                <tr key={item.product_id}>
                  <Td>{item.product_id}</Td>
                  <Td>{item.name}</Td>
                  <Td>{item.category_name}</Td>
                  <Td>
                    <Button style={{ backgroundColor: item.location.toLowerCase() === "inside" ? "#4CAF50" : "#F44336" }}>
                      {item.location}
                    </Button>
                  </Td>
                  <Td>{new Date(item.create_date).toLocaleDateString()}</Td>
                  <Td>{item.group_names}</Td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        totalItems={filteredData.length}
        itemsPerPage={itemsPerPage}
        onPageChange={setCurrentPage}
      />
    </Container>
  );
};

export default Hierarchy;

// Styled Components (unchanged)
const Container = styled.div`
  padding: 20px;
  position: relative;
  background-color: #fff;
`;
export const CalContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-collapse: collapse;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
export const Title = styled.h2`
  background: yellow;
  padding: 10px;
  text-align: center;
  width: 100%;
`;
export const VisualizationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  padding: 20px 0px;
  border: 2px solid grey;
  background: linear-gradient(to bottom, #dbf4fc, #92ff9245);

  @media (min-width: 768px) {
    width: 70%;
  }
`;
export const CalenderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  padding: 20px;
  border: 2px solid grey;
  background: linear-gradient(to bottom, #edfaff94, #cacaca9b);
  width: 100%;

  @media (min-width: 768px) {
    width: 25%;
  }
`;
export const SearchBar = styled.input`
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  border: 1px solid gray;
  border-radius: 4px;
`;
export const TableContainer = styled.div`
  border: 2px solid red;
  margin: 10px 5px;
  overflow-x: auto;
`;
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  justify-content: center;
`;
export const Th = styled.th`
  background: #f5f5f5;
  padding: 10px;
  align-items: center;
  border: 1px solid #ddd;
`;
export const Td = styled.td`
  padding: 5px;
  font-size: 14px;
  justify-content: center;
  border: 1px solid #ddd;
  text-align: center;
`;
export const Button = styled.button`
  min-width: 150px;
  padding: 6px 10px;
  background-color: ${(props) => (props.active ? "#4CAF50" : "#333")};
  color: white;
  border: none;
  border-radius: 4px;
  margin: 3px 10px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    opacity: 0.8;
  }
`;