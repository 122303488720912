import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import profile from "../Assets/ProfileIcon.png";
import Logstix from "../Assets/Logstix.png";
import { SidebarData } from "./SidebarData";
import { IconContext } from "react-icons/lib";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useAppUtils, { usePermission } from "../utils/useAppUtils";
import { BlueBackgroundButton } from "../components/Global/GlobalButtons";
import { FiLogOut } from "react-icons/fi";
import { gsap } from "gsap";
import {
  setNewUserVerify,
  userCheckAction,
  userDataAction,
} from "../redux/users/action";
import { ImProfile } from "react-icons/im";
import { LOGOUT_USER } from "../redux/type";
import Notification from "./Notification";
import axios from "axios";
import { API_BASE_URL_USER } from "../utils/https";
import { IoMdNotificationsOutline } from "react-icons/io";

export default function Layout({ children }) {
  const { navigate } = useAppUtils();
  const [activeMenu, setActiveMenu] = useState(null);
  const userCheck = useSelector((state) => state?.users?.userCheck);
  const userDetails = useSelector((state) => state?.users?.user);
  const location = useLocation();
  const token = localStorage.getItem("token");
  const { permission: permissionsArray } = usePermission();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotifiOpen, setIsNotifiOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const permissionIds = permissionsArray.map(
    (permission) => permission.permission_id
  );
  useEffect(() => {
    localStorage.setItem("lastVisitedPage", location.pathname);
  }, [location]);
  useEffect(() => {}, [permissionsArray]);
  const getFilteredSidebarData = () => {
    const role = localStorage.getItem("role");
    if (
      userDetails.role === "admin" ||
      role === "groupadmin" ||
      userDetails.role === "warehouse"
    ) {
      return SidebarData();
    }
    return SidebarData().map((item) => {
      const isItemAllowed =
        Array.isArray(item.id) &&
        item.id.some((id) => permissionIds.includes(id));
      if (item.subNav) {
        item.subNav = item.subNav.map((subItem) => ({
          ...subItem,
          isDisabled: !(
            Array.isArray(subItem.id) &&
            subItem.id.some((id) => permissionIds.includes(id))
          ),
        }));
      }
      return {
        ...item,
        isDisabled: !isItemAllowed,
      };
    });
  };
  const filteredSidebarData = getFilteredSidebarData();
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL_USER}/getNotification`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setNotifications(response.data);
        console.log("jdchd", response);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [notifications]);
  const handleClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleNotificationHover = () => {
    setIsNotifiOpen(true);
  };
  const handleNotificationLeave = () => {
    setIsNotifiOpen(false);
  };

  const hideSidebar =
    location.pathname === "/choose_portal" ||
    location.pathname === "/newlogin" ||
    location.pathname === "/waiting-unitapproove";

  return (
    <Root className="root">
      <Nav>
        <div style={{ display: "flex", alignItems: "center" }}>
          <NavIcon to="#" style={{ flexDirection: "column" }}>
            <img src={Logstix} alt="Logstix Logo" />
          </NavIcon>
          <H1Tag>
            Logstix
            <span> SIMPLE ENGAGING ORGANIZED </span>
          </H1Tag>
        </div>
        {!hideSidebar && userCheck && token ? (
          <>
            <div className="notification-profile">
              <div
                onMouseEnter={handleNotificationHover}
                style={{ color: "red" }}
              >
                <IoMdNotificationsOutline
                  style={{ color: "red", width: "30px", height: "30px" }}
                />
                {notifications.totalcount}
              </div>
              {isNotifiOpen && (
                <Notification
                  handleNotificationLeave={handleNotificationLeave}
                  name={userDetails?.username}
                  notifications={notifications}
                  setNotifications={setNotifications}
                />
              )}
              <p>{userDetails?.username}</p>
              <img
                className="profile"
                src={profile}
                alt="profile"
                onMouseEnter={() => {
                  handleClick();
                }}
              />
            </div>
            {isDropdownOpen && <Dropdown />}
          </>
        ) : (
          !token && (
            <>
              <div
                onClick={() => {
                  navigate("/newlogin");
                }}
              >
                <BlueBackgroundButton>New User Login</BlueBackgroundButton>
              </div>
            </>
          )
        )}
      </Nav>
      <div className="main_body">
        {!hideSidebar && userCheck && token && (
          <div className="sideBar">
            <IconContext.Provider value={{ color: "#fff" }}>
              <SidebarNav className="sidebar_society">
                <SidebarWrap>
                  {filteredSidebarData.map((item, index) => (
                    <React.Fragment key={index}>
                      <SidebarLink
                        to={item.isDisabled ? "#" : item.path}
                        className={
                          item.isDisabled ? "disabled-sidebar-item" : ""
                        }
                        onClick={(e) => {
                          if (item.isDisabled) {
                            e.preventDefault(); // Disable the click
                          } else if (item.subNav) {
                            setActiveMenu((prevActiveMenu) =>
                              prevActiveMenu === item.title ? null : item.title
                            );
                          }
                        }}
                        activeclassname="selected"
                      >
                        <div className="icon_content">
                          {item.icon}
                          <SidebarLabel className="side_label">
                            {item.title}
                          </SidebarLabel>
                        </div>
                        <div className="open_close_icon">
                          {item.subNav && activeMenu === item.title
                            ? item.iconOpened
                            : item.subNav
                            ? item.iconClosed
                            : null}
                        </div>
                      </SidebarLink>

                      {item.subNav &&
                        activeMenu === item.title &&
                        item.subNav.map((subItem, subIndex) => (
                          <DropdownLink
                            to={subItem.isDisabled ? "#" : subItem.path}
                            key={subIndex}
                            activeclassname="selected"
                            className={
                              subItem.isDisabled ? "disabled-sidebar-item" : ""
                            }
                            onClick={(e) => {
                              if (subItem.isDisabled) {
                                e.preventDefault();
                              }
                            }}
                          >
                            {subItem.icon}
                            <SidebarLabel>{subItem.title}</SidebarLabel>
                          </DropdownLink>
                        ))}
                    </React.Fragment>
                  ))}
                </SidebarWrap>
              </SidebarNav>
            </IconContext.Provider>
          </div>
        )}
        <div className="page">{children}</div>
      </div>
    </Root>
  );
}

function Dropdown() {
  const { dispatch, navigate } = useAppUtils();

  const loginTimestamp = localStorage.getItem("loginTimestamp");
  const token = localStorage.getItem("token");
  const notificationRef = useRef(null);

  useEffect(() => {
    console.log("Slide-in animation starting...");
    gsap.to(notificationRef.current, {
      x: -500, // Move in from the right
      duration: 0.5,
      ease: "power2.out",
    });
    return () => {
      console.log("Slide-out animation starting...");
      gsap.to(notificationRef.current, {
        x: 0, // Move out to the right
        duration: 0.5,
        ease: "power2.in",
      });
    };
  }, []);

  useEffect(() => {
    const maxDuration = 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds
    const warningTime = 5 * 60 * 1000; // 5 minutes before expiration

    const checkSessionValidity = () => {
      if (token && loginTimestamp) {
        const elapsedTime = Date.now() - parseInt(loginTimestamp, 10);

        if (elapsedTime > maxDuration) {
          handleLogout(); // Automatically log out the user
        } else if (
          elapsedTime > maxDuration - warningTime &&
          elapsedTime <= maxDuration
        ) {
          alert("Your session is about to expire. Please save your work.");
        }
      }
    };
    const interval = setInterval(checkSessionValidity, 60 * 1000);
    return () => clearInterval(interval);
  }, [loginTimestamp]);

  const handleLogout = () => {
    localStorage.removeItem("lastVisitedPage");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("loginTimestamp");
    dispatch({ type: LOGOUT_USER });
    navigate("/");
    dispatch(userCheckAction(false));
    dispatch(setNewUserVerify(true));
    dispatch(userDataAction(""));
  };
  const user = useSelector((state) => state?.users?.user);

  const handleNavigate = () => {
    localStorage.getItem("token", user.id);
    console.log("localStorage.getItem", localStorage.getItem("token", user.id));
    navigate(`/all_user_profile/${user.id}`, { state: user });
  };
  const handleMouseLeave = () => {
    gsap.to(notificationRef.current, {
      x: 500,
      duration: 0.5,
      ease: "power2.in",
      // onComplete: handleNavigate,
    });
  };
  return (
    <div
      className="dropdown"
      ref={notificationRef}
      onMouseLeave={handleMouseLeave}
    >
      <ul>
        <li>
          <div className="icon_content">
            <ImProfile />
            <SidebarLabel className="side_label">
              <a
                href={`/all_user_profile/${user.id}`}
                onClick={() => {
                  handleNavigate();
                }}
                style={{ textDecoration: "none", color: "#343c6a" }}
              >
                User Details
              </a>
            </SidebarLabel>
          </div>
        </li>
        <li>
          <div
            className="icon_content"
            onClick={() => {
              handleLogout();
            }}
          >
            <FiLogOut />
            <SidebarLabel className="side_label">Logout</SidebarLabel>
          </div>
        </li>
      </ul>
    </div>
  );
}
const Root = styled.section`
  min-height: 100vh;
  max-width: 100vw;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .disabled-sidebar-item {
    pointer-events: none;
    opacity: 0.5;
    filter: blur(1px);
    cursor: not-allowed !important;
  }

  .disabled-sidebar-item .icon_content,
  .disabled-sidebar-item .side_label {
    pointer-events: none;
    cursor: not-allowed;
  }
  .dropdown {
    color: #343c6a;
    position: absolute;
    right: -500px;
    box-shadow: 1px 3px 2px 1px lightgray;
    border-radius: 8px;
    border: 2px solid #343c6a;
    top: 80px;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
    ul {
      list-style: none;
      padding: 10px 16px;
      margin: 0;
      li {
        margin: 10px 0px;
        padding: 3px 6px;
        border-radius: 8px;
        cursor: pointer;
        border: 2px solid #343c6a;
        &:hover {
          background-color: lightgray;
        }
      }
      svg {
        color: #343c6a;
      }
    }
  }
  .main_body {
    display: flex;
    flex: 1;
    min-height: 94vh;
    height: 100%;
    min-width: 100vw;
    width: 100%;
    background-color: #e5f4fb;
    overflow: hidden;
    margin-top: 5.2%;

    @media (max-width: 567px) {
      margin-top: 20%;
    }

    @media (min-width: 567px) and (max-width: 992px) {
      margin-top: 8%;
    }

    .sideBar {
      width: 25%;
      position: static;
      @media (max-width: 567px) {
        &:hover {
          width: 60%;
        }
      }
      @media (min-width: 567px) and (max-width: 992px) {
        width: 45%;
      }
    }

    .sidebar_society {
      @media (max-width: 567px) {
        &:hover {
          .side_label {
            display: block;
          }
        }

        &:hover {
          .sub_menu {
            display: block;
            padding: 5px 5px 30px 35px;
          }
        }

        &:hover {
          .open_close_icon svg {
            display: block;
          }
        }
      }
    }

    .page {
      border-radius: 20px;
      height: 100%;
      padding: 20px 30px;
      width: 100%;
      @media (max-width: 567px) {
        padding: 20px 10px;
        width: 85%;
      }
    }
  }

  @media (max-width: 567px) {
    .side_label {
      display: none;
    }

    .icon_content {
      display: flex;
    }

    .sub_menu {
      display: none;
    }

    .open_close_icon svg {
      display: block;
    }
  }
`;

const SidebarLink = styled(NavLink)`
  display: flex;
  color: #000;
  justify-content: space-between;
  align-items: center;
  padding: 10px 3px;
  list-style: none;
  height: 20px;
  background-color: #fff;
  text-decoration: none;
  font-size: 16px;
  border: 6px solid #fff;
  margin: 10px;
  svg {
    color: inherit;
  }

  &:hover {
    color: #2d60ff;
    border-left: 5px solid #2d60ff;
    cursor: pointer;
  }

  &.selected,
  &.active {
    color: #2d60ff;
    background-color: #fff;
    border-left: 6px solid #2d60ff;
  }
  @media (min-width: 367px) and (max-width: 992px) {
    margin:10px 0px;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 15px;
  font-size: 15px;
`;

const DropdownLink = styled(NavLink)`
  background: #fff;
  height: 30px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  font-size: 16px;
  border: 4px solid #fff;
  background-color: lightgray;
  border-radius: 20px;
  padding: 1rem 0 1rem 3rem;

  &:hover {
    color: #2d60ff;
    border: 4px solid #fff;
    cursor: pointer;
    svg {
      color: #2d60ff;
    }
  }

  &.selected,
  &.active {
    color: #fff;
    background-color: #2d60ff;
    border-radius: 20px;
    border: 4px solid #fff;
    svg {
      color: #fff;
    }
  }
`;

const Nav = styled.div`
  background: #fff;
  color: #343c6a;
  height: 80px;
  max-width: 100vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: static;
  padding: 10px 20px;
  box-shadow: 1px 1px 4px 1px #f2f2f2;
  position: fixed;
  z-index: 1111;
  .icon_phone {
    display: none;
  }
  .notification-profile {
    display: flex;
    gap: 20px;
    align-items: center;
    p {
      margin: 0;
      text-transform: capitalize;
      font-weight: 500;
    }
    .notification {
      height: 40px;
      width: 40px;
    }
    .profile {
      height: 60px;
      width: 60px;
    }
  }
  @media (max-width: 876px) {
    padding: 0px 20px;
    .icon_phone {
      display: block;
    }
  }
`;

const SidebarNav = styled.nav`
  background: transparent;
  width: 20%;
  position: fixed !important;
  color: #000;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  left: ${({ sidebar, isMobile }) => (isMobile && !sidebar ? "-100%" : "0")};
  transition: 350ms;
  z-index: 10;
  margin-top: 6%;

  @media (max-width: 567px) {
    margin-top: 20%;
    width: 15%;
    &:hover {
      width: 60%;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    width: 30%;
    margin-top: 8%;
  }
`;

const SidebarWrap = styled.div`
  overflow-y: auto;
  padding: 20px 0px;
  width: 100%;
  box-shadow: 1px 1px 4px 1px #f2f2f2;
  svg {
    color: #000 !important;
    width: 20px;
    height: 20px;
  }
`;

const NavIcon = styled(Link)`
  display: none;
  margin: 0px;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    color: #000 !important;
    height: auto;
    width: 20px;
  }
  img {
    height: 65px;
    width: auto;
  }
  @media (max-width: 876px) {
    display: flex;
    svg{
      width: 15px;
    }
    img {
      height: 46px;
      width: auto;
    }
  }
`;

const H1Tag = styled.h1`
  text-align: center;
  margin-left: 20px;
  color: #343c6a;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-family: Inter;
  font-size: 26px;
  line-height: 33px;

  span {
    font-size: 10px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  @media (max-width: 876px) {
    font-size: 20px;
    margin: 0;
    span {
      font-size: 8px;
      line-height: 14px;
    }
  }
`;
