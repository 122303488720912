import React, { useState } from "react";
import styled from "styled-components";
import GlobalTable from "../Global/GlobalTable";
import { BlackBorderButton } from "../Global/GlobalButtons";
import useAppUtils, { useUserList } from "../../utils/useAppUtils";
import { Pagination } from "../Global/Table2";
import { setSelectedUserProfileId } from "../../redux/users/action";
import { useSelector } from "react-redux";

const UsersList = () => {
  const users = useUserList();
  const { navigate,dispatch } = useAppUtils();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const totalItems = users.length;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedUsers = users.slice(startIndex, startIndex + itemsPerPage);
  const findRole = useSelector((state)=> state?.users?.user?.role);
  console.log("findRole",findRole)
  const columns = [
    { header: "Select", accessor: "select" },
    { header: "User Name", accessor: "userName" },
    { header: "Group Name", accessor: "groupName" },
    { header: "Unit Name", accessor: "unitName" },
    { header: "Role ", accessor: "role" },
    { header: "Request Sent ", accessor: "request" },
  ];

  return (
    <StyleUserList>
      <div className="dash_table">
        <GlobalTable
          columns={columns}
          data={paginatedUsers.map((user) => ({
            userName: (
              <span
              onClick={() => {
                dispatch(setSelectedUserProfileId(user.id));
                if (findRole === "admin" || findRole === "groupadmin") {
                  navigate(`/all_user_profile/${user.id}`, { state: user });
                }
              }} 
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {user.username || "No Data"}
              </span>
            ),
            groupName: user.groupname || "No Data",
            unitName: user.unitname || "No Data",
            role: user.role || "No Data",
            request: (
              <BlackBorderButton onClick={() => alert(`Request Accepted`)}>
                Accepted
              </BlackBorderButton>
            ),
          }))}
        />
      </div>
      <PaginationWrapper>
        <Pagination
          currentPage={currentPage}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </PaginationWrapper>
    </StyleUserList>
  );
  
};

export default UsersList;
const StyleUserList = styled.section`
background-color: #fff;
padding: 20px;
border-radius: 20px;
`;
const PaginationWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;