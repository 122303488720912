import React, { useState } from "react";
import GlobalSearch from "../Global/GlobalSearch";
import styled from "styled-components";
import GlobalTable from "../Global/GlobalTable";
import "react-calendar/dist/Calendar.css";
import { useGroupList } from "../../utils/useAppUtils";
import { useDispatch } from "react-redux";
import { setSelectedGroupIds } from "../../redux/users/action";
import { useNavigate } from "react-router-dom";
import { Pagination } from "../Global/Table2";

export const AllGroup = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");

  const groups = useGroupList();

  const columns = [
    { header: "Select", accessor: "select" },
    { header: "SL No", accessor: "group_id" },
    { header: "Create Date & Time", accessor: "dateTime" },
    { header: "Created By", accessor: "createdBy" },
    { header: "Group List", accessor: "groupList" },
    { header: "Unit Name", accessor: "unitList" },
    { header: "Member List", accessor: "memberList" },
    { header: "equipment List", accessor: "equipmentList" },
  ];

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filterSearchData = groups.filter((groupname) =>
    groupname.group_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNavigateToUnit = (groupid) => {
    dispatch(setSelectedGroupIds(groupid));
    navigate(`/group_byid/${groupid}`);
  };

  // Pagination Logic
  const paginateData = (data, currentPage, itemsPerPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const paginatedGroups = paginateData(filterSearchData, currentPage, itemsPerPage);

  return (
    <Div>
      <div>
        <GlobalSearch search="Search for Something" value={searchQuery} onChange={handleSearchChange}/>
      </div>
       

      <TableDiv>
        <div className="dash_table">
          <GlobalTable
            columns={columns}
            data={paginatedGroups.map((group, index) => ({
              group_id: group.group_id,
              dateTime: group.create_date
                ? formatDate(group.create_date)
                : "No Data",
              createdBy: group.role || "No Role",
              groupList: (
                <span
                  onClick={() => handleNavigateToUnit(group.group_id)}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  {group.group_name || "No Data"}
                </span>
              ),
              unitList: `${group.unitList}` || "No Data",
              memberList: group.user_count || "No Data",
              equipmentList: group.equipment_permission_count || "No Data",
            }))}
          />
        </div>
        <Pagination
          currentPage={currentPage}
          totalItems={groups.length}
          itemsPerPage={itemsPerPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </TableDiv>
    </Div>
  );
};
export const Div =styled.section`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
`


export const ProductList = styled.div`
  padding: 20px 0px 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ButtonDiv = styled.div`
  display: flex;
  gap: 20px;
`;

export const TableDiv = styled.section`
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  .dash_table {
    background-color: #ffff;
    border-radius: 25px;
    padding: 8px;
    overflow: auto;
  }
`;

