import React from "react";
import styled from "styled-components";
import AddUserTab from "./AddUserTab";
export const InviteUserTab = () => {
  
  return (
    <Root>
       <AddUserTab/>
    </Root>
  );
};
const Root = styled.section`
 
`;
